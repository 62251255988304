<template>
  <form-duration />
</template>

<script>
import formDuration from '../form/medication/Form-medication-duration.vue'

export default ({
  components: {
    formDuration,
  },
})
</script>
