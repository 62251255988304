<template>
  <div class="m-1">
    <b-row>
      <b-col
        cols="5"
      >
        <b-form-group
          label="Data de início"
          label-for="medication"
        >

          <validation-provider
            #default="{ errors }"
            name="data de início"
            rules="required"
          >

            <b-form-datepicker
              v-model="dateInitial"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt"
              placeholder=""
              :min="today()"
              @input="save()"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-form-group>

      </b-col>
      <b-col
        cols="5"
      >
        <b-form-group
          label="Data de término"
          label-for="medication"
        >

          <validation-provider
            #default="{ errors }"
            name="data de término"
            rules="required"
          >

            <b-form-datepicker
              v-model="dateEnd"
              :min="today()"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt"
              placeholder=""
              @input="save()"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,

    ValidationProvider,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      dateInitial: '',
      dateEnd: '',
    }
  },

  mounted() {
    this.dateInitial = this.$store.getters['formMedication/durationDateInitial']
    this.dateEnd = this.$store.getters['formMedication/durationDateEnd']
  },

  methods: {
    today() {
      if (this.dateInitial === '') {
        return moment().format('YYYY-MM-DD')
      }
      return this.dateInitial
    },
    save() {
      this.$store.commit('formMedication/UPDATE_DURATION_DATE_INITIAL', this.dateInitial)
      this.$store.commit('formMedication/UPDATE_DURATION_DATE_END', this.dateEnd)
    },
  },

}
</script>
