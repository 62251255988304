<template>

  <div>

    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <h4 class="mt-2 pt-2">
        {{ this.$store.getters['formMedication/action'] }}
      </h4>
      <div class="mt-2 d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="items" />
      </div>
      <validation-observer ref="durationRules">
        <b-form>
          <b-row>

            <b-col
              cols="6"
            >
              <b-form-group
                class="mt-2"
              >
                Duração

                <b-form-radio
                  v-model="selectedDuration"
                  class="mt-1"
                  value="Uso contínuo (sem data de término)"
                >
                  Uso contínuo (sem data de término)
                </b-form-radio>

                <b-form-radio
                  v-model="selectedDuration"
                  class="mt-1"
                  value="Quantidade de dias (início e fim definidos)"
                >
                  Quantidade de dias (início e fim definidos)
                </b-form-radio>

                <template v-if="link === 'patient'">
                  <form-duration-date v-if="selectedDuration === 'Quantidade de dias (início e fim definidos)'" />
                </template>

                <template v-if="link === 'favorite'">
                  <b-form-radio
                    v-model="selectedDuration"
                    class="mt-1"
                    value="Não selecionar uma duração agora"
                  >
                    Não selecionar uma duração agora
                  </b-form-radio>
                </template>

              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-col
        cols="12"
        class="mt-2"
      >
        <b-button
          type="submit"
          variant="outline-primary"
          @click="cancel"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="outline-primary"
          class="ml-2"
          @click="previous"
        >
          Voltar
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          class="ml-2"
          @click="next"
        >
          Continuar
        </b-button>
      </b-col>

    </b-card>

    <form-abstract />

  </div>

</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BBreadcrumb, BForm, BRow, BCol, BFormGroup, BButton, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formDurationDate from './Form-medication-duration-date.vue'
import formAbstract from './Form-medication-abstract.vue'

export default {
  components: {
    BCard,
    BBreadcrumb,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormRadio,

    formDurationDate,
    formAbstract,

    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      link: '',
      selectedDuration: '',
      option: [],
      items: [
        {
          text: 'Medicamento',
          active: true,
        },
        {
          text: 'Horário',
          active: true,
        },
        {
          text: 'Frequência',
          active: true,
        },
        {
          text: 'Duração',
          active: false,
        },
        {
          text: 'Resumo',
          active: true,
        },
      ],
    }
  },

  mounted() {
    this.selectedDuration = this.$store.getters['formMedication/duration']
    this.link = this.$store.getters['formMedication/link']
  },

  methods: {
    next() {
      this.$refs.durationRules.validate().then(success => {
        if (success) {
          this.$store.commit('formMedication/UPDATE_SHOW_DURATION', true)
          this.$store.commit('formMedication/UPDATE_DURATION', this.selectedDuration)
          this.$router.push(`${this.link}-finish`)
        }
      })
    },
    cancel() {
      if (this.link === 'patient') {
        this.$store.commit('patient/UPDATE_SHOW_TAB', true)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
        this.$router.push(`${this.link}-edit`)
      } else {
        this.$router.push(this.link)
      }
    },
    previous() {
      this.$store.commit('formMedication/UPDATE_SHOW_FREQUENCY', false)
      this.$router.push(`${this.link}-frequency`)
    },
  },
}
</script>
